.videoPlayerModal {
    :global {
        .ant-modal-content {
            .ant-modal-body {
                padding: 0;
            }
        }
        
    }
    .videoMask {
        height: 504px;
        position: relative;
        video {
            width: 100%;
            height: 100%;
            background-color: black;
        }
        .closeIcon {
            position: absolute;
            top: 25px;
            right: 25px;
            width: 32px;
            height: 32px;
            display: flex;
            justify-content: center;
            cursor: pointer;
            span {
                font-size: 18px;
                width: 18px;
                height: 18px;
                color: #fff;
            }
        }
        .preIcon {
            position: absolute;
            top: 190px;
            right: 28px;
            font-size: 22px;
            color: #fff;
            cursor: pointer;
        }
        .nextIcon {
            position: absolute;
            top: 240px;
            right: 28px;
            font-size: 22px;
            color: #fff;
            cursor: pointer;
        }
    }
    .videoContent {
        height: 140px;
        padding: 16px 32px;
        .videoTitle {
            width: 100%;
            height: 32px;
            font-size: 20px;
            font-weight: 600;
            color: rgba(0, 0, 0, 0.85);
            overflow: hidden;
            text-overflow:ellipsis;
            white-space: nowrap;
        }
        .iconGroup {
            width: 100%;
            height: 24px;
            font-size: 16px;
            color: rgba(0, 0, 0, 0.65);
            ul {
                display: flex;
                li {
                    width: 92px;
                    margin-left: 62px;
                }
                li:nth-child(1) {
                    margin-left: 0;
                }
                img {
                    width: 16px;
                    height: 16px;
                }
            }
        }
        .modalBottom {
            height: 32px;
            display: flex;
            justify-content: space-between;
            margin-top: 16px;
            .userInfo {
                display: flex;
                .imgContainer {
                    width: 22px;
                    height: 22px;
                    margin-right: 6px;
                    img {
                        width: 100%;
                        height: 100%;
                        border-radius: 50%;
                    }
                }
                span {
                    font-size: 14px;
                    color: rgba(0, 0, 0, 0.65);
                    padding-top: 2px;
                }
            }
        }
    }
}