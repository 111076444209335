.user {
  .bread {
    height: 60px;
    border-bottom: 1px #e8e8e8 solid;
    margin-bottom: 23px;
    font-size: 20px;
    font-weight: 600;
    color: rgba(0, 0, 0, 0.85);
    line-height: 60px;
    display: flex;
    align-items: center;
    justify-content: space-between;
    .breadItem {
      display: flex;
      flex-grow: 3;
    }
    .teach {
      font-size: 14px;
      font-weight: 400;
      color: rgba(0, 0, 0, 0.85);
      cursor: pointer;
    }
  }
  .content {
    margin: 48px auto;
    width: 552px;
    .name {
      font-size: 20px;
      font-weight: 400;
      color: #000000;
      line-height: 28px;
      display: flex;
      align-items: center;
      .vip {
        width: 38px;
        height: 20px;
        text-align: center;
        line-height: 20px;
        background: linear-gradient(90deg, #ffa353 0%, #ffd336 100%);
        border-radius: 4px;
        font-size: 14px;
        font-weight: 600;
        color: #ffffff;
        margin-left: 8px;
      }
    }
    .phone {
      margin-top: 20px;
      height: 20px;
      font-size: 14px;
      font-weight: 400;
      color: rgba(0, 0, 0, 0.45);
      line-height: 20px;
    }
    .line {
      width: 552px;
      height: 1px;
      border: 1px solid #e8e8e8;
      margin: 32px 0 7px;
    }
    .resTime,
    .endTime {
      height: 22px;
      font-size: 16px;
      font-weight: 400;
      color: rgba(0, 0, 0, 0.65);
      line-height: 22px;
      margin-top: 16px;
    }
    .countNum {
      margin-top: 16px;
      font-size: 16px;
      font-weight: 400;
      color: rgba(0, 0, 0, 0.85);
      line-height: 22px;
      display: flex;
      .countNumBox {
        flex: 1;
      }
    }
    .vipDetail {
      width: 552px;
      margin-top: 16px;
      .title {
        font-size: 16px;
        font-weight: 600;
        color: rgba(0, 0, 0, 0.85);
        line-height: 22px;
        margin-bottom: 24px;
      }
    }
  }
}
