// .searchForm {
//   .btn {

//  :global {
//   .ant-form-item-control-input-content {
//     display: flex;
//     justify-content: space-between;
//     padding-right: 24;
//   }
//  }
//   }
//   position: relative;
//     :global {
//       // .ant-picker {
//       //   width: 170px;
//       // }
//       .ant-form-item {
//         min-width: 212px;
//         // margin: 0 20px 20px 0!important;
//         width: 256px;
//         margin-bottom: 16px;
//         margin-right: 24px;
//       }
//       // .ant-form-item-control-input-content {
//       //   .ant-form-item {
//       //     min-width: 90px!important;
//       //   }
//       // }
//     }
// }

.searchForm {
  width: calc(100vw - 60px);
  .btnBox {
    width: 100%;
 :global {
  .ant-form-item-control-input-content {
    display: flex;
    justify-content: space-between;
    padding-right: 24;
  }
 }
  }
  position: relative;
    :global {
      // .searchFormItem:nth-child(5n) {
      //   padding-right: 0;
      // }
      .searchFormItem {
        display: flex;
        flex-shrink: 3;
        // width: calc(20vw - 12px);
        width: calc(20vw - 17px);
        min-width: 260px;
        max-width: 367px;
        margin-bottom: 16px;
        margin-right:0 ;
        padding-right: 20px;
        .ant-form-item {
          margin-right: 0;
          display: flex;
          flex-shrink: 3;
         }
      }
     
      .ant-input-group-compact {
        display: flex!important;
        .ant-input-number {
          display: flex;
          flex-shrink: 3;
        }
        .ant-input-number {
          width: auto;
          display: flex;
          flex-shrink: 3;
        }
      }
    }
}