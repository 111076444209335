.footerBox {
  background: #2b2e33;
  color: #fff;
  font-size: 16px;
  .footer {
    max-width: 1200px;
    margin: 0 auto;
    // padding: 30px 0;
    text-align: left !important;

    .footerLogo {
      margin-bottom: 20px;
      display: flex;
      align-items: center;
      justify-content: space-between;
      .tel {
        .logo {
          height: 18px;
          margin-bottom: 8px;
        }
        margin-bottom: 8px;
        font-size: 14px;
        font-weight: 600;
        color: rgba(255, 255, 255, 0.65);
        line-height: 20px;
        .title {
          margin-bottom: 8px;
        }
        .innerT {
          .vip{
            font-weight: 600;
          }
          font-weight: 400;
        }
        .innerI {
          color: #999;
          font-size: 14px;
          font-weight: 400;
        }
      }
      .concept {
        width: 290px;
        font-size: 16px;
        font-weight: 600;
        color: rgba(255, 255, 255, 0.65);
        line-height: 22px;
        .innerT {
          margin-bottom: 8px;
        }
        .innerI {
          font-size: 14px;
          font-weight: 400;
        }
      }
      .code {
        width: 220px;
        img {
          width: 78px;
          height: 78px;
          margin-right: 32px;
          margin-bottom: 4px;
        }
        font-size: 14px;
        color: #999;
      }
    }
  }
  .copyRight {
    padding: 20px 0 0;
    text-align: center;
    border-top: 1px #1f1f1f solid;

    font-size: 14px;
    font-weight: 400;
    color: rgba(255, 255, 255, 0.25);
  }
}
