.info {
  display: flex;
  width: 384px;
  cursor: pointer;
  padding: 8px 16px ;
  margin-left: -16px;
  box-sizing: border-box;
  background: #ffffff;
  // box-shadow: 0px 2px 6px 0px rgba(107, 120, 131, 0.12);
  border-radius: 4px;
  margin-bottom: 15px;
  &:hover {
    box-shadow: 0px 2px 6px 0px rgba(107, 120, 131, 0.12);
  }
  img {
    // width: 64px;
    // height: 64px;
    width: 72px;
    height: 97px;
    cursor: pointer;
  }
  .infoBox {
    margin-left: 17px;
    .infoTop {
      display: flex;
      align-items: center;
      margin-bottom: 4px;
      .title {
        font-size: 16px;
        font-weight: 600;
        color: rgba(0, 0, 0, 0.65);
        line-height: 22px;
        font-weight: 600;
        color: rgba(0, 0, 0, 0.85);
        cursor: pointer;
      }
      .code {
        margin-left: 7px;
        img {
          width: 16px;
          height: 16px;
          border-radius: 0;
        }
      }
    }
    .infoItem {
      display: flex;
      font-size: 16px;
      align-items: center;
      font-weight: bold;
      color: rgba(0, 0, 0, 0.85);
      line-height: 19px;
      img {
        font-size: 16px;
        font-weight: bold;
        color: rgba(0, 0, 0, 0.85);
        width: 15px;
        height: 15px;
        margin-right: 4px;
      }
    }
    .followers {
      font-size: 14px;
      font-weight: 400;
      color: rgba(0, 0, 0, 0.45);
      line-height: 20px;
      margin-bottom: 3px;
    }
    .infoContent {
      font-size: 12px;
      font-weight: 400;
      color: rgba(0, 0, 0, 0.45);
      line-height: 18px;

      text-overflow: -o-ellipsis-lastline;
      overflow: hidden;
      text-overflow: ellipsis;
      display: -webkit-box;
      -webkit-line-clamp: 2;
      line-clamp: 2;
      -webkit-box-orient: vertical;
    }
    .tags {
      display: flex;
      margin-top: 4px;
      flex-wrap: wrap;
      // .tagItem {
      //   height: 20px;
      //   border: 1px solid rgba(0, 0, 0, 0.25);
      //   display: flex;
      //   align-items: center;
      //   justify-content: center;
      //   margin-right: 8px;
      //   padding: 0 8px;
      //   margin-bottom: 4px;

      //   border-radius: 2px;
      //   font-size: 12px;
      //   font-weight: 400;
      //   color: #46485A;
      // }
    }
  }
}
