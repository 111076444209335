.part {
  
  :global {
    .ant-spin-dot-spin {
      position: fixed!important;
      left: 40%;
      right: 0;
      margin: 0 auto;
      top: 35%;
    }
    .partForm {
      display: block;
      .ant-form-item-control-input {
        min-height: 0!important;
      }
      .ant-form-item-label > label {
        max-width: 260px;
        align-items: center;
        display: inline-flex;
        white-space: pre-wrap;
        white-space: pre-line;
        line-height: 16px;
        // max-height:64px ;
    }
    }
  }
  .titleBox {
    height: 32px;
    background: #F8F8F8;
    display: flex;
    align-items: center;
    padding-left: 24px;
    margin-bottom: 24px;
    .title {
      font-size: 14px;
      font-weight: 600;
      color: rgba(0, 0, 0, 0.85);
    }
    .content {
font-size: 12px;
    }
  }
  .tabBox {
    height: 32px;
    // background: #F8F8F8;
    display: flex;
    align-items: center;
    margin-left: 24px;
    margin-bottom: 24px;
    .tabName {
      font-size: 14px;
      font-weight: 600;
      color: rgba(0, 0, 0, 0.85);
    }
  }
}