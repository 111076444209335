.modalContent {
    padding: 62px 84px;
    width: 100%;
    .main {
        max-height: 514px;
        overflow-y: auto;
        // 修改默认滚动条
        &::-webkit-scrollbar {
	        width: 8px;
        }
        &::-webkit-scrollbar-track {
	        background-color: #fff;
        }
        &::-webkit-scrollbar-thumb {
	        border-radius: 6px;
	        background-color: #ECECEC;
        }
        .title {
            width: 548px;
            font-size: 18px;
            font-weight: 600;
            color: #000000;
            line-height: 24px;
        }
        .date {
            width: 100%;
            height: 22px;
            font-size: 12px;
            font-family: HelveticaNeue;
            color: rgba(0, 0, 0, 0.25);
            line-height: 22px;
            margin-top: 8px;
        }
        .content {
            font-size: 14px;
            color: #3C3C44;
            line-height: 22px;
            margin-top: 16px;
        }
    }
    
}