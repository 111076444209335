.video_detail,.video_learn {
    cursor: pointer;
    width: 24px;
    height: 24px;
    display: inline-block;
  }
.video_detail {
    margin-right: 16px;
    // background: url('./../../../assets/img/x.png');
    background: url('./../../../assets/img/x2.png');
    background-size: cover;
    // &:hover {
    //   background: url('./../../../assets/img/x2.png');
    //   background-size: cover;
    // }
}
.video_learn {
    // background: url('./../../../assets/img/s.png');
    background: url('./../../../assets/img/s2.png');
    background-size: cover;
    // &:hover {
    //   background: url('./../../../assets/img/s2.png');
    //   background-size: cover;
    // }
}