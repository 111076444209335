.myConllectionComtainer {
    .btnBox {
        width: 100%;
        height: 32px;
        margin: 32px 0 16px;
        display: flex;
        flex-wrap: nowrap;
        justify-content: flex-end;
        .rightBtnGroup {
            :global {
                .ant-btn:nth-child(1) {
                    margin-right: 8px;
                }
            }
        }
    }
    .empty {
        width: 100%;
        height: 285px;
        padding: 16px;
        display: flex;
        align-items: center;
        justify-content: center;
        img {
            width: 280px;
            height: 211px;
        }
    }
}