@import './../index.scss';
.login {
  width: 100vw;
  height: 100vh;
  position: fixed;
  top: 0;
  z-index: 100000;
  display: flex;
  background: rgba(0, 0, 0, 0.45);
  align-items: center;
  justify-content: center;
  .loginForm {
    padding: 60px 60px 30px ;
    position: relative;
    height: 420px;
    :global {
      .ant-form-item-explain-error {
        font-size: 12px;
        padding-left: 10px;
      }
    }
    .title {
      text-align: center;
      font-size: 24px;
      font-weight: 600;
      color: #333333;
      line-height: 33px;
      margin-bottom: 16px;
    }
    .loginType {
      width: 60px;
      height: 60px;
      position: absolute;
      right: 16px;
      top: 16px;
      img {
        width: 100%;
        height: 100%;
      }
    }
    .code {
      width: 210px;
      height: 210px;
      margin: 0 auto 24px;
      img {
        width: 100%;
        height: 100%;
      }
    }
    width: 420px;
    // height: 420px;
    background: #FFFFFF;
border-radius: 8px;
    .btn {
      width: 100%;
      display: flex;
      margin-top: 47px;
      :global {
        .ant-btn {
          width: 100%;
          height: 46px;
          font-weight: bolder;
        }
      }
    }
    .mark {
      font-size: 14px;
      font-weight: 400;
      color: rgba(0, 0, 0, 0.45);
      line-height: 20px;
      margin-top: 16px;
      .toRegister {
        color: #1890FF;
        cursor: pointer;
      }
    }
    .codemark {
      font-size: 14px;
      font-weight: 400;
      color: rgba(0, 0, 0, 0.85);
      text-align: center;
    }
    :global {
      .ant-form {
        display: block;
      }
      .headerBox{
        display: none!important;
      }
      .rootLayout {
        margin: 0!important;
      }
      .ant-input {
        border-top: none;
        border-left: none;
        border-right: none;
        border-bottom: 1px #E8E8E8 solid;
        &:focus {
          box-shadow: none;
        }
      }
      .ant-input-affix-wrapper {
        border-top: none;
        border-left: none;
        border-right: none;
        border-bottom: 1px #E8E8E8 solid;
        position: relative;
        .ant-input {
          border: none;
          width: 150px;
        }
        .ant-input-suffix {
          height: 42px;
          width: auto;
          position: absolute;
          right: 0;
          bottom: 2px;
          cursor: pointer;
          // display: block;
          img {
            height: 100%;
            width: auto;
          }
        }
      }
      .ant-input-affix-wrapper:focus, .ant-input-affix-wrapper-focused {
        box-shadow: none;
      }
    }
    
  }
}
