.mask {
  width: 100vw;
  height: 100vh;
  z-index: 1000000;
  position: fixed;
  left: 0;
  top: 0;
  background-color: rgba(0,0,0,40%);
  display: flex;
  justify-content: center;
  align-items: center;
  .videoBox {
    width: 860px;
    height:644px;
    position: relative;
    video {
      height: 503px;
      width: 860px;
      background-color: black;
    }
    .btnBox {
      position: absolute;
      color: #FFFFFF;
      font-size: 18px;
      top: 32px;
      right: 32px;
      .close,.pre,.next {
        cursor: pointer;
      }
      .pre {
        margin-top: 150px;
        margin-bottom: 40px;
      }
    }
  }
}