.list {
  padding: 0 0 20px;
  padding: 0 32px 20px;
  .bread {
    height: 60px;
    border-bottom:1px #E8E8E8 solid;
    margin-bottom: 23px;
    font-size: 20px;
    font-weight: 600;
    color: rgba(0, 0, 0, 0.85);
    line-height: 60px;
    display: flex;
    align-items: center;
    justify-content: space-between;
    .breadItem {
      display: flex;
      flex-grow: 3;
      :global {
        .ant-tabs-tab-btn {
          font-size: 16px!important;
        }
        .ant-tabs-top {
          margin-top: 29px;
        }
      }
    }
    .teach {
      font-size: 14px;
      font-weight: 400;
      color: rgba(0, 0, 0, 0.85);
      cursor: pointer;
    }
  }
  .empty {
    display: flex;
    flex-direction: column;
    justify-content: center;
    align-items: center;
    padding-top: 10px;
    min-height: 211px;
    img {
      width: 280px;
      height: 211px;
    }
  }
  .listBtn {
    display: flex;
    padding: 16px 0;
    justify-content: space-between;
  }
  .btnLeft {
    :global {
      button {
        margin-right: 10px;
      }
      .ant-btn-primary {
        color: #fff;
      }
    }
  }
  .btnRight {
    display: flex;
  }
  :global {
    .operation {
      display: flex;
      align-items: center;
      justify-content: space-between;
      width: 60px;
      flex-wrap: wrap;
    }
    .x,
    .c,
    .y,
    .b {
      cursor: pointer;
      width: 24px;
      height: 24px;
      display: inline-block;
    }
    .x {
      // margin-right: 16px;
      // background: url('./../../../assets/img/x.png');
      background: url("./../../../../assets/img/x2.png");
      background-size: cover;
      // &:hover {
      //   background: url('./../../../assets/img/x2.png');
      //   background-size: cover;
      // }
    }
    .c {
      // background: url('./../../../assets/img/c.png');
      background: url("./../../../../assets/img/c2.png");
      background-size: cover;
      // &:hover {
      //   background: url('./../../../assets/img/c2.png');
      //   background-size: cover;
      // }
    }
    .y {
      // background: url('./../../../assets/img/c.png');
      background: url("./../../../../assets/img/yi.png");
      background-size: cover;
      margin-top: 15px;
      // &:hover {
      //   background: url('./../../../assets/img/c2.png');
      //   background-size: cover;
      // }
    }
    .b {
      margin-top: 15px;
      // background: url('./../../../assets/img/c.png');
      background: url("./../../../../assets/img/biao.png");
      background-size: cover;
      // &:hover {
      //   background: url('./../../../assets/img/c2.png');
      //   background-size: cover;
      // }
    }
  }
}
