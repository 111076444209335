.addVip {
  width: 360px!important;
  .confirmcontent {
    display: flex;
    flex-direction: column;
    justify-content: space-between;
    align-items: center;
font-weight: 600;
height: 260px;
.vipt {
  color: rgba(0, 0, 0, 0.85);
line-height: 28px;
letter-spacing: 1px;

font-size: 20px;
}
img {
  height: 200px;
  width: 200px;
}
.vipc{

font-size: 18px;
  font-weight: 400;
color: rgba(0, 0, 0, 0.85);
line-height: 25px;
letter-spacing: 1px;
}
  }
}