.userBox {
  font-weight: 600;
  background-color: #FFFFFF;
  box-shadow: 0px 4px 12px 0px rgba(0, 0, 0, 0.15);
  border-radius: 4px;
  // padding: 26px 24px;
  padding: 26px 0px;
  .top {
    padding: 0 24px;
    .vip {
      font-size: 12px;
      color: rgba(0, 0, 0, 0.65);
      font-weight: 400;
      line-height: 20px;
      letter-spacing: 1px;
      padding-bottom: 16px;
      border-bottom: 1px solid #E8E8E8;
      display: flex;
      justify-content: space-between;
      align-items: center;
      flex-wrap: wrap;
      .icon {
        width: 38px;
        height: 22px;
        background: linear-gradient(90deg, #FFA353 0%, #FFD336 100%);
        border-radius: 4px;
        display: flex;
        align-items: center;
        justify-content: center;

        font-size: 12px;
        font-weight: 600;
        color: #FFFFFF;
      }
      .endDay {
        font-weight: 400;
        color: #F02828;
        font-size: 12px;
        width: 150px;
        margin-top: 9px;
      }
    }
    .getVip {
      font-size: 12px;
      color: rgba(0, 0, 0, 0.65);
      font-weight: 400;
      line-height: 20px;
      letter-spacing: 1px;
      padding-bottom: 16px;
      border-bottom: 1px solid #E8E8E8;
      display: flex;
      justify-content: space-between;
      align-items: center;
      .getvipicon {
        width: 75px;
        height: 28px;
        background: #F8AC59;
        border-radius: 15px;
        display: flex;
        align-items: center;
        justify-content: center;

        font-size: 12px;
        font-weight: 600;
        color: #FFFFFF;
      }
    }
  }
  .content {
    // padding-top: 7px;
    .contentItem {
      padding: 3px 24px;
      font-size: 16px;
      font-weight: 500;
      color: rgba(0, 0, 0, 0.65);
      line-height: 30px;
      letter-spacing: 1px;
      cursor: pointer;
      &:hover {
        background-color: #F5F7FA;
      }
    }
  }
}