.shipinContainer {
    padding: 0 32px 20px;
    .tabContainer {
        height: 56px;
        :global {
            .ant-tabs-nav {
                margin-bottom: 0px;
            }
            .ant-tabs-nav-list {
                height: 56px;
                line-height: 24px;
                .ant-tabs-tab {
                    font-size: 16px;
                    color: #333333;
                    font-weight: 600;
                }
            }
        }
    }
}