.info {
  display: flex;
  width: 384px;
  cursor: pointer;
  padding: 8px 16px 8px 0;
  box-sizing: border-box;
  
  &:hover {
    box-shadow: 0px 2px 6px 0px rgba(107, 120, 131, 0.12);
  }
  img {
    width: 64px;
    height: 64px;
    border-radius: 50%;
    cursor: pointer;
  }
  .infoBox {
    margin-left: 17px;
    .infoTop {
      display: flex;
      align-items: center;
      margin-bottom: 4px;
      .title {
        font-size: 16px;
        font-weight: 600;
        color: rgba(0, 0, 0, 0.65);
        line-height: 22px;
    
        cursor: pointer;
      }
      .code {
        margin-left: 7px;
        img {
          width: 16px;
          height: 16px;
          border-radius: 0;
        }
      }
    }
    .followers {
      font-size: 14px;
      font-weight: 400;
      color: rgba(0, 0, 0, 0.45);
      line-height: 20px;
      margin-bottom: 3px;
    }
    .infoContent {
      font-size: 12px;
      font-weight: 400;
      color: rgba(0, 0, 0, 0.45);
      line-height: 18px;

      text-overflow: -o-ellipsis-lastline;
      overflow: hidden;
      text-overflow: ellipsis;
      display: -webkit-box;
      -webkit-line-clamp: 2;
      line-clamp: 2;
      -webkit-box-orient: vertical;
    }
    .tags {
      display: flex;
      margin-top: 4px;
      flex-wrap: wrap;
      // .tagItem {
      //   height: 20px;
      //   border: 1px solid rgba(0, 0, 0, 0.25);
      //   display: flex;
      //   align-items: center;
      //   justify-content: center;
      //   margin-right: 8px;
      //   padding: 0 8px;
      //   margin-bottom: 4px;

      //   border-radius: 2px;
      //   font-size: 12px;
      //   font-weight: 400;
      //   color: #46485A;
      // }
    }
  }
}