.video_detail, .video_continue, .video_close, .video_show {
    cursor: pointer;
    width: 24px;
    height: 24px;
    display: inline-block;
  }
.video_detail {
    margin-right: 16px;
    // background: url('./../../../assets/img/x.png');
    background: url('./../../../assets/img/x2.png');
    background-size: cover;
    // &:hover {
    //   background: url('./../../../assets/img/x2.png');
    //   background-size: cover;
    // }
}
.video_continue {
    // background: url('./../../../assets/img/c.png');
    background: url('./../../../assets/img/c2.png');
    background-size: cover;
    // &:hover {
    //   background: url('./../../../assets/img/c2.png');
    //   background-size: cover;
    // }
}
.video_close {
    // background: url('./../../../assets/img/ub.png');
    background: url('./../../../assets/img/ub2.png');
    background-size: cover;
    // &:hover {
    //   background: url('./../../../assets/img/ub2.png');
    //   background-size: cover;
    // }
}
.video_show {
    // background: url('./../../../assets/img/b.png');
    background: url('./../../../assets/img/b2.png');
    background-size: cover;
    // &:hover {
    //   background: url('./../../../assets/img/b2.png');
    //   background-size: cover;
    // }
}