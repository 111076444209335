.searchFold {
    width: 100%;
    position: relative;
    .searchBtnUnfold {
        position: absolute;
        top: 64px;
        left: calc(73.2% + 64px);
    }
    :global {
        .ant-form {
            .ant-row {
                margin-top: 16px;
            }
            .ant-form-item {
                margin-right: 16px;
                .ant-form-item {
                    margin-right: 0px;
                }
                .ant-btn {
                    margin-right: 8px;
                }
                .ant-btn:nth-child(2) {
                    margin-right: 0px;
                }
            }
        }
        .ant-input-group{
            .ant-row {
                margin-top: 0px;
            }
        }
    }
}
.searchUnfold {
    width: 100%;
    height: 112px;
    position: relative;
    .searchBtnUnfold {
        position: absolute;
        top: 64px;
        left: calc(73.2% + 64px);
    }
    :global {
        .ant-form {
            .ant-row {
                margin-top: 16px;
            }
            .ant-form-item {
                margin-right: 16px;
                .ant-form-item {
                    margin-right: 0px;
                }
                .ant-btn {
                    margin-right: 8px;
                }
                .ant-btn:nth-child(2) {
                    margin-right: 0px;
                }
            }
        }
        .ant-input-group{
            .ant-row {
                margin-top: 0px;
            }
        }
    }
}
.foldCase {
    display: flex;
    flex-direction: row;
}
.searchUnfold {
    width: 100%;
    position: relative;
}