.goods {
  .banner {
    width: 100%;
    position: relative;
    overflow: hidden;
    .bannerContent {
      position: absolute;
      width: 1200px;
      top: 20%;
      margin: 0 auto;
      left: 0;
      right: 0;
      font-weight: 500;
      color: #ffffff;
      .title {
        font-size: 42px;
      }
      .inner {
        font-size: 20px;
        margin-top: 8px;
      }
      .bannerBtn {
        width: 180px;
        height: 57px;
        background: linear-gradient(123deg, #00d29d 0%, #00a650 100%);
        border-radius: 4px;
        cursor: pointer;
        font-size: 22px;
        font-weight: 500;
        color: #ffffff;
        line-height: 57px;
        margin-top: 62px;
        text-align: center;
      }
      .popup {
        position: fixed;
        width: 100vw;
        height: 100vh;
        top: 0;
        left: 0;
        background: rgba(0, 0, 0, 0.8);
        z-index: 10000;
        .popup_conter {
          width: 284px;
          height: 284px;
          padding: 30px;
          background: #fff;
          position: relative;
          left: 50%;
          top: 50%;
          transform: translateX(-50%) translatey(-50%);
          background: #39404f;
          box-sizing: border-box;
          border-radius: 24px;
          .popup_img {
            margin: 0 auto;
            width: 228px;
            height: 225px;
            .popup_img img {
              display: block;
              width: 100%;
              height: 100%;
            }
          }
          .popup_conter p {
            line-height: 30px;
            text-align: center;
            font-size: 12px;
            color: #fff;
          }
        }
      }
    }
    img {
      width: 100%;
      height: auto;
      top: 0;
    }
  }
  .goodsPrice {
    background: #161823;
    .title {
      font-size: 36px;
      font-weight: 600;
      color: #ffffff;
      line-height: 50px;
      text-align: center;
      padding: 92px 0 62px;
    }
    .priceItemBox {
      width: 1200px;
      margin: 0 auto;
      position: relative;
      display: flex;
      justify-content: space-between;
      flex-wrap: wrap;
      padding-right: 270px;
      .priceItem {
        width: 458px;
        height: 290px;
        border: 1px solid #00b469;
        border-radius: 6px;
        margin-bottom: 14px;
        position: relative;
        background-color: #161823;
        padding: 30px;
        box-sizing: border-box;
        overflow: hidden;
        cursor: pointer;
        .icon_actived {
          width: 34px;
          height: 34px;
          background: url(../../assets/img/p2.png) no-repeat;
          position: absolute;
          top: -1px;
          right: -1px;
        }
        h3 {
          color: #fff;
          margin-bottom: 15px;
          span {
            color: #f5a825;
            b {
              color: #fff;
              font-size: 12px;
            }
          }
        }
        p {
          color: #d8d8d8;
          font-size: 12px;
          margin-bottom: 15px;
        }
        ul {
          list-style: none;
          padding: 0;
          margin-top: 25px;
          li {
            line-height: 30px;
            height: 30px;
            background: url(../../assets/img/p.png) no-repeat;
            background-size: 15px 10px;
            background-position: 0 9px;
            padding-left: 22px;
            color: #d8d8d8;
          }
        }
        .bottom_info {
          width: 100%;
          height: 44px;
          background: #202238;
          position: absolute;
          bottom: 0;
          left: 0;
          color: #fff;
          line-height: 44px;
          padding-left: 30px;
          font-size: 12px;
          a {
            font-weight: 400;
            color: #ffffff;
          }
        }
      }
      .pay_product_price_content_paybtn {
        background: url(../../assets/img/price.png) no-repeat;
        position: absolute;
        right: 0;
        width: 252px;
        height: 590px;
        padding: 43px 0 0 34px;
        .payTitle {
          // width: 66px;
          height: 20px;
          font-size: 14px;
          font-weight: 500;
          color: #d8d8d8;
          line-height: 20px;
          margin-top: 16px;
        }
        .price {
          display: flex;
          align-items: flex-end;
          vertical-align: bottom;
          padding-top: 8px;
          margin-bottom: 16px;
          span {
            display: flex;
            line-height: 1;
          }
          span:nth-child(1) {
            font-size: 33px;
            font-weight: bold;
            color: #f5a825;
          }
          span:nth-child(2) {
            font-size: 20px;
            color: #ffffff;
          }
          span:nth-child(3) {
            font-size: 14px;
            font-weight: 500;
            color: #f5a825;
            text-decoration: line-through;
          }
        }
        .btn {
          width: 117px;
          height: 34px;
          background: linear-gradient(131deg, #ffd7c3 0%, #c4a578 100%);
          border-radius: 4px;
          font-size: 14px;
          font-weight: 400;
          color: #422c13;
          line-height: 34px;
          text-align: center;
          cursor: pointer;
        }
      }
    }
  }
  .vipBox {
    background: #161823;
    padding-bottom: 320px;
    .title {
      font-size: 36px;
      font-weight: 600;
      color: #ffffff;
      line-height: 50px;
      text-align: center;
      padding: 92px 0 62px;
    }
    .vipTable {
      width: 1200px;
      margin: 0 auto;
      img {
        width: 1200px;
        height: auto;
      }
    }
  }
}
