.video_detail,.video_cancel {
    cursor: pointer;
    width: 24px;
    height: 24px;
    display: inline-block;
  }
.video_detail {
    margin-right: 16px;
    // background: url('./../../../assets/img/x.png');
    background: url('./../../../assets/img/x2.png');
    background-size: cover;
    // &:hover {
    //   background: url('./../../../assets/img/x2.png');
    //   background-size: cover;
    // }
}
.video_cancel {
    // background: url('./../../../assets/img/sh.png');
    background: url('./../../../assets/img/sh2.png');
    background-size: cover;
    // &:hover {
    //   background: url('./../../../assets/img/sh2.png');
    //   background-size: cover;
    // }
}