.readCaseContainer {
    width: 345px;
    .title {
        max-height: 48px;
        font-size: 16px;
        font-weight: 600;
        color: rgba(0, 0, 0, 0.85);
        line-height: 24px;
        display: -webkit-box;
        -webkit-box-orient: vertical;
        -webkit-line-clamp: 2;
        overflow: hidden;
    }
    .author {
        height: 20px;
        font-size: 14px;
        color: rgba(0, 0, 0, 0.45);
        line-height: 20px;
    }
    .date {
        height: 20px;
        font-size: 12px;
        color: rgba(0, 0, 0, 0.25);
        line-height: 20px;
    }
    .content {
        height: 66px;
        font-size: 14px;
        color: rgba(0, 0, 0, 0.45);
        line-height: 22px;
        display: -webkit-box;
        -webkit-box-orient: vertical;
        -webkit-line-clamp: 3;
        overflow: hidden;
        margin-top: 16px;
        
    }
    .readBtn {
        float: right;
        height: 22px;
        font-size: 14px;
        line-height: 22px;
        color: #2B99FF;
        cursor: pointer;
    }
}