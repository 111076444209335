.groupbox{
 :global{
   .ant-spin {
     position: fixed!important;
     left: 0;
     right: 0;
     height: 100vh!important;
     max-height: 100vh
   }
   .ant-alert {
    align-items: flex-start;
    margin-bottom: 32px;
    .anticon {
      margin-top: 4px;
    }
     .groupalert {
       display: flex;
       font-size: 14px;
  font-weight: 400;
  color: rgba(0, 0, 0, 0.65);
  line-height: 22px;
  .gruopBtn {
    font-size: 14px;
  font-weight: 400;
  color: #25B864;
  line-height: 20px;
  display: flex;
  align-items: flex-end;
  cursor: pointer;
  }
     }
   }
  
 }
  .group {
    display: flex;
    // justify-content: space-between;
    flex-wrap: wrap;
    .new,.ntnew {
      height: 280px;
      border-radius: 8px;
      border: 1px dashed rgba(0, 0, 0, 0.65);
      display: flex;
      justify-content: center;
      align-items: center;
      width: calc(25vw - 40.5px);
      cursor: pointer;
      margin-bottom: 30px;
      font-size: 14px;
      font-weight: 400;
      color: rgba(0, 0, 0, 0.65);
      line-height: 20px;
      pointer-events:none
      &:hover {
        box-shadow: 0px 3px 10px 0px rgba(0, 0, 0, 0.25);
        border-radius: 8px;
        border: 1px dashed #25b864;
        color: #25b864;
      }
    }
    .ntnew {
      cursor: not-allowed;
      &:hover {
        box-shadow: none;
       
      }
    }
  }
}
