.register {
  width: 100vw;
  height: 100vh;
  position: fixed;
  z-index: 100000;
  display: flex;
  background: rgba(0, 0, 0, 0.45);
  align-items: center;
  justify-content: center;
  top: 0;
  .registerForm {
    padding: 60px 60px 30px ;
    position: relative;
    height: 420px;
    .title {
      text-align: center;
      font-size: 24px;
      font-weight: 600;
      color: #333333;
      line-height: 33px;
      margin-bottom: 40px;
    }
    .code {
      width: 180px;
      height: 180px;
      margin: 0 auto 37px;
      img {
        width: 100%;
      }
    }
    width: 420px;
    // height: 420px;
    background: #FFFFFF;
border-radius: 8px;
    .btn {
      width: 100%;
      display: flex;
      margin-top: 40px;
      :global {
        .ant-btn {
          width: 100%;
          height: 48px;
          font-weight: bolder;
        }
      }
    }
    :global {
      .ant-form {
        display: block;
      }
      .headerBox{
        display: none!important;
      }
      .rootLayout {
        margin: 0!important;
      }
      .ant-input {
        border-top: none;
        border-left: none;
        border-right: none;
        border-bottom: 1px #E8E8E8 solid;
        &:focus {
          box-shadow: none;
        }
      }
      .ant-input-affix-wrapper {
        border-top: none;
        border-left: none;
        border-right: none;
        border-bottom: 1px #E8E8E8 solid;
        position: relative;
        .ant-input {
          border: none;
          width: 150px;
        }
        .ant-input-suffix {
          height: 42px;
          width: auto;
          position: absolute;
          right: 0;
          bottom: 2px;
          cursor: pointer;
          img {
            height: 100%;
            width: auto;
            margin: 0;
          }
        }
      }
      .ant-input-affix-wrapper:focus, .ant-input-affix-wrapper-focused {
        box-shadow: none;
      }
    }
  }
}