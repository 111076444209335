.addCount {
  :global {
    .ant-form-Vertical {
      display: block;
      .ant-form-item {
        width: 100%;
        .ant-picker {
          width: 100%!important;
        }
      }
    }
  }
}