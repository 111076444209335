.list {
  padding: 0 0 20px;
  .empty {
    display: flex;
    flex-direction: column;
    justify-content: center;
    align-items: center;
    padding-top:10px ;
    min-height: 211px;
    img {
      width: 280px;
      height: 211px;
    }
  }
  :global {
    .draft,.submit,.hide {
      width: 52px;
      height: 20px;
      border-radius: 4px;
      font-size: 12px;
      font-weight: 500;
      display: flex;
      align-items: center;
      justify-content: center;
    }
    .draft {
      color: #FFB438;
      background: rgba(255, 180, 56, 0.08);
    }
    .submit {
     

      background: rgba(26, 179, 148, 0.08);
      color: #1AB394;
    }
    .hide {
      color: #E5303A;
      background: rgba(247, 53, 63, 0.08);
    }
  }
  .listBtn {
    display: flex;
    padding: 16px 0;
    justify-content: space-between;
  }
  .btnLeft {
    :global {
      button {
        margin-right: 10px;
      }
      .ant-btn-primary {
        color: #fff;
      }
    }
  }
  .btnRight {
    display: flex;
  }
  :global {
    .x,.c,.h,.s,.j {
      cursor: pointer;
      width: 24px;
      height: 24px;
      display: inline-block;
    }
    .x {
      margin-right: 16px;
      // background: url('./../../../assets/img/x.png');
      background: url('./../../../assets/img/x2.png');
      background-size: cover;
      // &:hover {
      //   background: url('./../../../assets/img/x2.png');
      //   background-size: cover;
      // }
    }
    .c {
      // background: url('./../../../assets/img/c.png');
      background: url('./../../../assets/img/c2.png');
      background-size: cover;
      margin-right: 16px;
      // &:hover {
      //   background: url('./../../../assets/img/c2.png');
      //   background-size: cover;
      // }
    }
    .h {
      // background: url('./../../../assets/img/ub.png');
      background: url('./../../../assets/img/ub2.png');
      background-size: cover;
      margin-right: 16px;
      // &:hover {
      //   background: url('./../../../assets/img/ub2.png');
      //   background-size: cover;
      // }
    }
    .s {
      // background: url('./../../../assets/img/b.png');
      background: url('./../../../assets/img/b2.png');
      background-size: cover;
      margin-right: 16px;
      // &:hover {
      //   background: url('./../../../assets/img/b2.png');
      //   background-size: cover;
      // }
    }
    .j {
      background: url('./../../../assets/img/jb.png');
      background-size: cover;
    }
  }
}
