.partBox {
  :global {
    .ant-pagination {
      width: 100%!important;
      margin-top: 32px;
      display: flex;
    }
  }
  .partList {
    // height: calc(100vh - 300px);
    max-height: 1000px;
    overflow: auto;
    .empty {
      display: flex;
      flex-direction: column;
      justify-content: center;
      align-items: center;
      padding-top:100px ;
      img {
        width: 280px;
        height: 211px;
      }
    }
  }
}