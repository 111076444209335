.partItem {
  padding: 32px 0;
  border-bottom:  1px solid #E9E9E9;
  .title {
    width: 522px;
    // height: 24px;
    font-size: 20px;
    font-weight: 600;
    color: #000000;
    line-height: 24px;
    margin-bottom: 8px;
  }
  .time {
    height: 22px;
    font-size: 14px;
    color: rgba(0, 0, 0, 0.25);
    line-height: 22px;
    margin-bottom: 16px;
  }
  .partTab {
    .partTabTitle {
      // width: 119px;
      height: 22px;
      font-size: 16px;
      font-weight: 600;
      color: rgba(51, 51, 51, 0.85);
      line-height: 22px;
      margin-bottom: 16px;
    }
    .partTabcontent {
      .contenItem {
        width: 770px;
        // height: 66px;
        font-size: 14px;
        font-weight: 400;
        color: rgba(0, 0, 0, 0.65);
        line-height: 22px;
        // margin-bottom: 16px;
        .contentInner {
          padding-bottom: 8px;
          .slotItem {
            font-size: 14px;
            font-weight: 400;
            color: rgba(0, 0, 0, 0.65);
            line-height: 22px;
            margin-bottom: 16px;
            span:first-of-type{
              font-weight: 500;
            }
            .slotSpan::after {
              content: '、';
            }
            .slotSpan:last-of-type{
              &::after {
                content: '';
              }
            }
          }
          // .slotItem:last-child::after {
          //   content: '';
          // }
        }
      }
    }
    
  }
  .operation {
    display: flex;
    .collect {
      cursor: pointer;
      font-size: 14px;
      font-weight: 400;
      color: rgba(0, 0, 0, 0.45);
      line-height: 22px;
      margin-right: 42px;
    }
    .unCollect {
      cursor: pointer;
      color:#25B864 ;
      .collectT {
        font-size: 14px;
        font-weight: 400;
        color: rgba(0, 0, 0, 0.45);
        line-height: 22px;
        margin-right: 42px;
      }
    }
  }
}