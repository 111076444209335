.info {
  display: flex;
  width: 384px;
  img {
    width: 64px;
    height: 64px;
    border-radius: 50%;
    cursor: pointer;
  }
  .infoBox {
    margin-left: 17px;
    .infoTop {
      display: flex;
      align-items: center;
      margin-bottom: 4px;
      .title {
        font-size: 16px;
        font-weight: 600;
        color: rgba(0, 0, 0, 0.65);
        line-height: 22px;
    
        cursor: pointer;
      }
      .code {
        margin-left: 7px;
        img {
          width: 16px;
          height: 16px;
          border-radius: 0;
        }
      }
    }
    .infoContent {
      font-size: 12px;
      font-weight: 400;
      color: rgba(0, 0, 0, 0.45);
      line-height: 18px;

      text-overflow: -o-ellipsis-lastline;
      overflow: hidden;
      text-overflow: ellipsis;
      display: -webkit-box;
      -webkit-line-clamp: 2;
      line-clamp: 2;
      -webkit-box-orient: vertical;
    }
    .tags {
      display: flex;
      margin-top: 4px;
      flex-wrap: wrap;
      .tagItem {
        height: 20px;
        // border: 1px solid rgba(0, 0, 0, 0.25);
        display: flex;
        align-items: center;
        justify-content: center;
        margin-right: 8px;
        padding: 0 8px;
        margin-bottom: 4px;
        border-radius: 4px;
        font-size: 12px;
        font-weight: 400;
        color: #46485A;
        background: rgba(26, 179, 148, 0.08);
        font-weight: 400;
        color: #25B864;
      }
    }
  }
}