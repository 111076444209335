.list {
  padding: 0 32px 20px;
  .bread {
    height: 60px;
    border-bottom:1px #E8E8E8 solid;
    margin-bottom: 23px;
    font-size: 20px;
    font-weight: 600;
    color: rgba(0, 0, 0, 0.85);
    line-height: 60px;
    display: flex;
    align-items: center;
    justify-content: space-between;
    .breadItem {
      display: flex;
      flex-grow: 3;
    }
    .teach {
      font-size: 14px;
      font-weight: 400;
      color: rgba(0, 0, 0, 0.85);
      cursor: pointer;
    }
  }
  .listBtn {
    display: flex;
    padding: 16px 0;
    justify-content: space-between;
  }
  .btnLeft {
    :global {
      button {
        margin-right: 10px;
      }
      .ant-btn-primary {
        color: #fff;
      }
    }
  }
  .btnRight {
    display: flex;
  }
}
.uiSelectBox{
  // width: 170px;
  max-height: 400px;
  margin: 0 -16px -12px -16px;
  overflow-y: auto;
  padding-bottom: 15px;
  .uiContentItem{
    height: 32px;
    line-height: 32px;
    padding: 0 22px;
    display: flex;
    // &:hover{
    //   background: $gray-7;
  }
}

