.learnContainer {
  padding: 0 32px 20px;
  .bread {
    height: 60px;
    border-bottom: 1px #e8e8e8 solid;
    margin-bottom: 23px;
    font-size: 20px;
    font-weight: 600;
    color: rgba(0, 0, 0, 0.85);
    line-height: 60px;
    display: flex;
    align-items: center;
    justify-content: space-between;
    .breadItem {
      display: flex;
      flex-grow: 3;
    }
  }
  .learnContent {
    width: 1200px;
    margin: 0 auto;
    padding-top: 25px;
    display: flex;
    .learnLeft {
      width: 352px;
      margin-right: 32px;
      .recommend {
        width: 100%;
        padding-top: 32px;
        display: flex;
        flex-wrap: wrap;
        .topTenList {
          width: 100%;
          .videoBox {
            width: 363px;
            height: 304px;
            video {
              width: 363px;
              height: 304px;
              background-color: black;
            }
          }
          .topTenListTitle {
            max-height: 48px;
            margin-top: 16px;
            font-size: 16px;
            font-weight: 600;
            color: rgba(0, 0, 0, 0.85);
            line-height: 24px;
            cursor: pointer;
            display: -webkit-box;
            -webkit-box-orient: vertical;
            -webkit-line-clamp: 3;
            overflow: hidden;
            &:hover {
              color: #25b864;
            }
          }
          .videoInfo {
            display: flex;
            justify-content: space-between;
            align-items: center;
            margin-top: 16px;
            margin-bottom: 16px;
            .infoItem {
              display: flex;
              align-items: center;
              font-size: 16px;
              font-weight: bold;
              color: rgba(0, 0, 0, 0.85);
              line-height: 19px;
              img {
                width: 18px;
                height: 18px;
              }
            }
          }
          .userInfo {
            width: 100%;
            height: 22px;
            display: flex;
            overflow: hidden;
            margin-bottom: 32px;
            flex-wrap: nowrap;
            .userImgContainer {
              width: 20px;
              height: 20px;
              border-radius: 50%;
              cursor: pointer;
              img {
                width: 100%;
                height: 100%;
                border-radius: 50%;
              }
            }
            .userName {
              height: 22px;
              font-size: 16px;
              font-weight: 600;
              color: rgba(0, 0, 0, 0.85);
              line-height: 22px;
              margin-left: 16px;
              cursor: pointer;
            }
            .codeIcon {
                width: 16px;
                height: 16px;
                margin-top: -2px;
                margin-left: 9px;
                img {
                    width: 100%;
                    height: 100%;
                }
            }
          }
          .toIndexBtn {
            border:solid 1px #25B864;
            color: #25B864;
            border-radius: 50px;
            width: 363px;
            text-align: center;
            height: 40px;
            font-size: 16px;
            vertical-align: middle;
            &:hover {
              background: rgb(37,184,100,.08);
            } 
          }
        }
      }
    }
    .learnRight{
        width: 791px;
        margin-left: 35px;
        .peopleNum {
          text-align: right;
          color: #25B864;
          margin-bottom: 12px;
        }
      }
  }
}
