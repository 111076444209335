.headerBox {
  background: #fff;
  box-shadow: 0 2px 30px 0 rgba(221, 221, 221, 0.5);
  position: fixed;
  top: 0;
  left: 0;
  right: 0;
  z-index: 100000;
  .header {
    position: relative;
    display: flex;
    align-items: center;
    justify-content: space-between;
    height: 64px;
    line-height: 64px;
    margin: 0 auto;
    :global {
      .usermenu .ant-menu-sub {
        width: 200px;
        margin-left: -90px;
      }
      .topmenu,.usermenu {
        display: flex;
        background-color: transparent;
        border: none;
      }
      .ant-menu-item-selected::after {
        opacity: 0;
      }
      .ant-menu-item-selected a {
        color: #25b864;
      }
      // .ant-menu-sub {
      //   display: flex;
      //   flex-direction: column;
      // }
      .ant-menu-submenu,
      .ant-menu-submenu-open {
        width: 115px !important;
      }
      .ant-menu-submenu:last-child {
        width: 170px;
        // width: 86px !important;
      }

      .headerMenu {
        font-size: 14px;
        font-weight: 500;
        .ant-menu-submenu-title {
          margin-left: -14px !important;
        }
        .ant-menu-inline {
          box-shadow: 0px 3px 8px 0px rgba(0, 0, 0, 0.1);
          .ant-menu-item {
            .ant-menu-title-content {
              a {
                font-weight:  normal;
              }
            }
          }
        }
      }
      .headerMenu:first-child {
        // width: 170px;
        // color: red !important;
        margin-left: 6px !important;
      }
      .ant-menu-submenu-inline {
        // height: 48px;
        // margin-top: 4px;
        // box-shadow: 2px 2px 2px #888888;
      }
      .ant-menu-sub {
        width: 150px;
      }
      .ant-menu-item-only-child {
        margin-bottom: 0;
        margin-top: 0;
      }
      .ant-menu-item {
        height: 48px;
        display: flex;
        align-items: center;
        width: auto;
        // padding: 0 12px!important;
        padding: 0 4px !important;
        background-color: #fff;

        .ant-menu-title-content {
          display: flex;
          justify-content: center;
          font-weight: 500;
          font-size: 14px;
          a {
            &:hover {
              color: #25b864;
            }
          }
          .headerLink {
            margin-right: 0;
          }
        }
      }
      // .active {
      //   color: #065EFF;
      // }
    }
    .logo {
      width: 180px;
      height: auto;
      cursor: pointer;
    }
    .tabs {
      font-size: 16px;
      font-weight: 400;
      // margin-left: 48px;
      line-height: 24px;
      display: flex;
      flex-grow: 2;
      position: absolute;
      top: 8px;
      left: 4px;
      img {
        height: 64px;
        margin-top: -8px;
        margin-right: 6vw;
        cursor: pointer;
      }
      a {
        margin-right: 24px;
        color: #3d4147;
      }
    
    }
    .user {
      display: flex;
      align-items: center;
      font-size: 16px;
      cursor: pointer;
      position: absolute;
      right: 32px;
      top: 8px;
      font-size: 14px;
      color: #3d4147;
      // width: 300px;
      .loginBox {
        display: flex;
        align-items: center;
        justify-content: space-between;
        height: 56px;
        width: 90px;
        .login {
          &:hover {
            color: #25B864;
          }
        }
        .register {
          &:hover {
            color: #25B864;
          }
        }
      }
      :global {
        .usermenu {
          width: 120px;
          margin-top: -8px;
          .topUsermenu {
            width: 120px!important;
            img {
              width: 16px;
              height: auto;
            }
          }
        }
      }
      .userBuy {
        width: 75px;
        width: 82px;
        height: 32px;
        padding: 4px 15px;
        // border: solid transparent 1px;
        background: linear-gradient(90deg, #ffd9c5 0%, #d1b088 100%);
        border-radius: 4px;
        font-size: 14px;
        font-weight: 400;
        color: #654733;
        display: flex;
        align-items: center;
        justify-content: space-between;
        // margin-right: 32px;
        flex-shrink: 1;
        position: absolute;
        top: 0;
        right:132px;
        img {
          margin-right: -10px;
          width: 16px;
          height: 16px;
        }
      }
      // .line {
      //   margin: 0 23px 0;
      //   width: 1px;
      //   height: 26px;
      //   opacity: 0.56;
      //   border: 1px solid #d8d8d8d8;
      // }
      .userCenter {
        padding-right: 10px;
        position: relative;
        display: flex;
        align-items: center;
        a {
          color: #3d4147;
        }
        :global {
          .active {
            color: #065eff;
          }
        }
        &::after {
          content: "";
          display: inline-block;
          width: 1px;
          height: 12px;
          background-color: #d8d8d8;
          margin-left: 6px;
        }
      }
      .userLogin {
        padding-right: 10px;
      }
      .userReg {
        background-color: #025eff;
        height: 36px;
        width: 100px;
        color: #fff;
        line-height: 36px;
        text-align: center;
        border-radius: 40px;
        &:hover {
          background-color: #0647b7;
        }
      }
    }
  }
}
:global {
  .loginCode {
    .qrcode {
      padding: 40px 0;
      display: flex;
      flex-direction: column;
      justify-content: center;
      position: relative;
      align-items: center;
      .dis {
        color: #333;
        font-size: 14px;
        padding-top: 15px;
      }
    }
  }
  .register {
    width: 100%;
    :global {
      .ant-btn-primary {
        width: 100%;
      }
    }
  }
}
