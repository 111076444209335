.groupItem {
  width: calc(25vw - 40.5px);
  margin-left: 26.6px;
  // height: 290px;
  background: #ffffff;
  box-shadow: 0px 3px 10px 0px rgba(0, 0, 0, 0.1);
  border-radius: 8px;
  border: 1px solid #e9e9e9;
  padding: 15px 0 0;
  cursor: pointer;
  margin-bottom: 30px;
  &:nth-child(4n+1) {
    margin-left: 0;
  }
  &:hover {
    box-shadow: 0px 3px 10px 0px rgba(0, 0, 0, 0.25);
    border-radius: 8px;
    border: 1px solid #25b864;
  }
  .name {
    font-size: 16px;
    font-weight: 500;
    color: rgba(0, 0, 0, 0.85);
    line-height: 24px;
    padding: 0 20px 8px;
    height: 32px;
    overflow: hidden;
    white-space:nowrap;
  }
  .daren {
    height: 30px;
    font-size: 14px;
    font-weight: 600;
    color: rgba(0, 0, 0, 0.65);
    line-height: 22px;
    padding: 0 0 8px;
    margin: 0 20px 0;
    border-bottom: 1px dashed rgba(0, 0, 0, 0.15);
  }
  .content {
    font-size: 12px;
    font-weight: 400;
    color: rgba(0, 0, 0, 0.45);
    line-height: 22px;
    padding: 15px 20px 16px;
    height:141px;
    .infoItem {
      font-size: 12px;
      font-weight: 400;
      color: rgba(0, 0, 0, 0.65);
      line-height: 22px;
    }
  }
  .operation {
    width: 100%;
    height: 60px;
    background: #f7faf8;
    border-radius: 0px 0px 8px 8px;
    display: flex;
    align-items: center;
    padding: 20px;
    font-size: 14px;
    font-weight: 400;
    .delete,
    .add,
    .details {
      height: 20px;
      display: flex;
      flex-grow: 2;
      width: calc(33.3% - 13.3px);
      cursor: pointer;
      &:hover {
        font-weight: 400;
        color: #25b864;
      }
    }
    .add {
      flex-basis: 50px;
      justify-content: center;
    }
    .delete {
      justify-content: center;
      // &::before,
      // &::after {
      //   content: "|";
      //   color: rgba(0, 0, 0, 0.2);
      //   width: 1px;
      // }
    }
    .details {
      justify-content: center;
      border-left: solid rgba(0, 0, 0, 0.2) 1px;
      border-right: solid rgba(0, 0, 0, 0.2) 1px;
      // &::before,
      // &::after {
      //   content: "|";
      //   color: rgba(0, 0, 0, 0.2);
      //   width: 1px;
      // }
    }
  }
}
