.video_info{
    display: flex;
    .video_img {
        width: 73px;
        height: 101px;
        box-sizing: border-box;
        border-radius: 2px;
        margin-right: 16px;
        position: relative;
        img {
            width: 100%;
            height: 100%;
        }
        .video_playicon {
            width: 24px;
            height: 24px;
            border-radius: 50%;
            background: rgba(0, 0, 0, 0.4);
            position: absolute;
            top: calc(50% - 12px);
            left: calc(50% - 12px);
            display: flex;
            justify-content: center;
            align-items: center;
            color: #fff;
        }
    }
    .video_right {
        display: flex;
        flex-direction: column;
        .video_title {
            width: 198px;
            height: 44px;
            font-size: 14px;
            font-weight: 600;
            color: rgba(0, 0, 0, 0.85);
            line-height: 22px;
            overflow : hidden;
            text-overflow: ellipsis;
            display: -webkit-box;
            -webkit-line-clamp: 2;
            -webkit-box-orient: vertical;
            cursor: pointer;
            &:hover {
                color: #25B864;
            }
        }
        .user_message {
            margin-top: 8px;
            height: 20px;
            box-sizing: border-box;
            display: flex;
            align-items: center;
            .user_img {
                width: 20px;
                height: 20px;
                box-sizing: border-box;
                margin-right: 8px;
                cursor: pointer;
                img {
                    width: 100%;
                    height: 100%;
                    border-radius: 50%;
                }
            }
            p {
                font-size: 14px;
                color: rgba(0, 0, 0, 0.65);
                line-height: 22px;
                padding-top: 3px;
                overflow: hidden;
                text-overflow: ellipsis;
                white-space: nowrap;
                margin-right: 8px;
                cursor: pointer;
            }
            .code_icon {
                width: 16px;
                height: 16px;
                margin-top: -5px;
                img {
                    width: 100%;
                    height: 100%;
                }
            }
        }
        .followers_num {
            margin-top: 8px;
            font-size: 14px;
            color: rgba(0, 0, 0, 0.65);
        }
    }
}
.video_detail,.video_dismantling {
    cursor: pointer;
    width: 24px;
    height: 24px;
    display: inline-block;
  }
.video_detail {
    margin-right: 16px;
    // background: url('./../../../assets/img/x.png');
    background: url('./../../../assets/img/x2.png');
    background-size: cover;
    // &:hover {
    //   background: url('./../../../assets/img/x2.png');
    //   background-size: cover;
    // }
}
.video_dismantling {
    // background: url('./../../../assets/img/c.png');
    background: url('./../../../assets/img/c2.png');
    background-size: cover;
    // &:hover {
    //   background: url('./../../../assets/img/c2.png');
    //   background-size: cover;
    // }
}
.columms_checkbox {
    display: flex;
    flex-direction: column;
    align-items: flex-start;
    .ant-checkbox-wrapper {
        margin-left: 0px;
    }   
}