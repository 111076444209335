.longInput {
    width: 18.3%;
    min-width: 248px;
}
.shortInput {
    width: 18.3%;
    min-width: 248px;
    .ant-input-group {
        display: flex;
        justify-content: space-between;
        .ant-form-item {
            width: calc(50% - 4px);
            .ant-input-number {
                width: 100%;
            }
        }
    }
}
.selectInput {
    width: 18.3%;
    min-width: 248px;
}