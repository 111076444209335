.works {
  padding: 0 32px 20px;
  :global {
    .ant-spin-dot-spin {
      position: fixed!important;
      left: 0;
      right: 0;
      margin: 0 auto;
      top: 35%;
    }
  }
  .bread {
    height: 60px;
    border-bottom:1px #E8E8E8 solid;
    margin-bottom: 23px;
    font-size: 20px;
    font-weight: 600;
    color: rgba(0, 0, 0, 0.85);
    line-height: 60px;
    display: flex;
    align-items: center;
    justify-content: space-between;
    .breadItem {
      display: flex;
      flex-grow: 3;
    }
    .teach {
      font-size: 14px;
      font-weight: 400;
      color: rgba(0, 0, 0, 0.85);
      cursor: pointer;
    }
  }
  .worksContent {
    width: 1200px;
    margin: 0 auto;
    padding-top: 25px;
    display: flex;
    .woksLeft {
      width: 352px;
      margin-right: 32px;
      .infoList {
        display: flex;
        align-items: center;
        justify-content: space-between;
        padding-bottom: 30px;
        padding-top: 25px;
        .infoItem {
          .itemTitle {
            font-size: 12px;
            font-weight: 400;
            color: rgba(0, 0, 0, 0.45);
            line-height: 17px;
          }
          .itemInner {
            margin-top: 4px;
            font-size: 14px;
            font-weight: bold;
            color: rgba(0, 0, 0, 0.85);
            line-height: 16px;
          }
        }
      }
      .recommend {
        padding-top: 30px;
        display: flex;
        flex-wrap: wrap;
        border-top: 1px solid #E8E8E8;
        .title {
          font-size: 16px;
          font-weight: 600;
          color: rgba(0, 0, 0, 0.85);
          line-height: 22px;
          flex: 1;
        }
        .change {
          font-size: 14px;
          font-weight: 400;
          color: rgba(0, 0, 0, 0.65);
          line-height: 22px;
          cursor: pointer;
          margin-bottom: 16px;
        }
        .unchange{
          font-size: 14px;
          font-weight: 400;
          color: rgba(0, 0, 0, 0.65);
          line-height: 22px;
          cursor: pointer;
          margin-bottom: 16px;
          // pointer-events: none;
          cursor: not-allowed;
          opacity: 0.4;
        }
        .recommendList {
          width: 100%;
          margin-left: -16px;
        }
      }
    }
    .woksRight{
      width: 791px;
      margin-left: 35px;
      .btnRight {
        padding-bottom: 24px;
        :global {
          .ant-btn {
            width: 120px;
            margin-right: 16px;
          }
        }
      }
      :global {
        .c {
          cursor: pointer;
          width: 24px;
          height: 24px;
          display: inline-block;
        }
        .c {
          margin-right: 16px;
          background: url('./../../../../assets/img/x.png');
          background-size: cover;
          &:hover {
            background: url('./../../../../assets/img/x2.png');
            background-size: cover;
          }
        }
        .worksTable {
          .worksInfo {
            display: flex;
            .worksimg {
              height: 105px;
              width: auto;
              position: relative;
              img {
                height: 105px;
                width: auto;
              }
              .play {
                position: absolute;
                left: 0;
                right: 0;
                margin: 0 auto;
                top: 40px;
                bottom: 0;
                width: 24px;
                height: 24px;
                display: flex;
                align-items: center;
                justify-content: center;
                border-radius: 50%;
                background: rgba(0, 0, 0, 0.8);
                font-size: 14px;
                color: #ffffff;
                cursor: pointer;
              }
            }
            .infotitle {
              width: 190px;
              margin-left: 22px;
              font-size: 14px;
              font-weight: 600;
              color: rgba(41, 43, 59, 0.85);
              line-height: 22px;
              cursor: pointer;
              &:hover {
                color: #25B864;
              }
            }
          }
          .ant-pagination {
            width: 100%!important;
          }
          .ant-pagination-options {
            display: none;
          }
        }
    }}
  }
}