.addvip {
  width: 759px;
  height: 590px;
  // width: 800px;
  width: 1200px;
  height: calc(100vh - 250px);
  max-height: 680px;
  // background: rgba(229, 229, 229, 0.6);
  // background: red;
  background: url('./../../assets/img/mh.png');

  background-size: cover;
  // opacity: .4;
  // filter: blur(1px);
  // filter: blur(6px);
  // filter: grayscale(50%);

  position: fixed;
  bottom: 0;
  left: 0;
  right: 0;
  margin: 0 auto;
  // right: calc(50vw - 610px);
  z-index: 100;
  display: flex;
  align-items: center;
  justify-content: center;
  &:after {
    content: "";
    background-color: rgb(5, 5, 5, .1);
    width: 100%;
    height: 100%;
    position: absolute;
    left: 0;
    top: 0;
    filter: blur(100px);
      // filter: drop-shadow(12px 12px 100px 1px rgb(97, 94, 94));
  }
  .btn {
    opacity: 1;
    position: relative;
    width: 520px;
    height: 437px;
    position: absolute;
    z-index: 3;
    img {
      width: 520px;
      height: 437px;
      opacity: 1;
    }
    :global {
      .ant-btn {
        position: absolute;
        right: 32px;
        bottom: 23px;
      }
    }
  }
}
