@import "./color.scss";

/* 初始化css */
html,
body,
#root {
  width: 100%;
  height: 100%;
}
#root {
  table {
    a {
      color: #25b864;
    }
  }
  overflow: auto;
  background: #ffffff;
}
body {
  margin: 0;
  font-family: -apple-system, BlinkMacSystemFont, "Segoe UI", "Roboto", "Oxygen", "Ubuntu", "Cantarell", "Fira Sans",
    "Droid Sans", "Helvetica Neue", sans-serif;
  -webkit-font-smoothing: antialiased;
  -moz-osx-font-smoothing: grayscale;
}

code {
  font-family: source-code-pro, Menlo, Monaco, Consolas, "Courier New", monospace;
}

p,
ul,
li {
  margin: 0;
  padding: 0;
}

ul {
  list-style: none;
}

input::-webkit-outer-spin-button,
input::-webkit-inner-spin-button {
  -webkit-appearance: none;
}

input[type="number"] {
  -moz-appearance: textfield;
}

/* 清楚元素浮动 */
.clearfix {
  *zoom: 1;
}
.clearfix:before,
.clearfix:after {
  display: table;
  line-height: 0;
  content: "";
}
.clearfix:after {
  clear: both;
}
.ant-layout-header {
  height: 96px;
  // padding: 0 50px;
  background-color: #ffffff;
  color: #666666;
  padding: 0;
}
.ant-table-content {
  overflow-y: visible !important;
  overflow-x: visible !important;
}
.tableFixed {
  // overflow: visible!important;
  .ant-table-thead {
    tr th {
      position: sticky;
      // position: fixed;
      z-index: 10000;
      top: 64px;
    }
  }
}
.table {
  // overflow: auto hidden!important;
  .ant-table-thead {
    position: static;
  }
}
.ant-pagination-options {
  display: flex;
  order: -1;
  flex-grow: 2;
}
.ant-pagination-total-text {
  display: flex;
  order: -2;
}
.ant-layout-footer {
  // background-color: #ffffff;
  // height: 150px;
  padding: 24px 0;
  text-align: center;
}
// .ant-steps-item-tail::after {
//   background-color: #D9D9D9!important;
// }
.rootLayout {
  margin: 80px auto 0;
  margin: 64px auto 0;
  background-color: #ffffff;
  position: relative;
  border-top: 16px solid #f4f7fa;
  min-height: calc(100vh - 279px);
}
.ant-form {
  display: flex;
  min-height: 48px;
  flex-wrap: nowrap;
  // overflow: hidden;
}
.searchForm {
  display: flex;
  flex-wrap: wrap;
  height: 48px;
  overflow: visible hidden;
  position: relative;
  width: calc(80vw - 48px);
  // width: calc(80vw - 52px);
}
.ant-message {
  z-index: 100000000;
}
.searchForm {
  .ant-picker {
    width: 100%;
    padding: 4px;

    .ant-picker-input > input {
      letter-spacing: -1px;
    }
    .ant-picker-range-separator {
      padding: 2px;
    }
    .ant-picker-active-bar {
      display: none;
    }
  }
}
.searchFormShow {
  display: flex;
  flex-wrap: wrap;
  height: auto;
  flex-shrink: 2;
  .ant-picker {
    width: 100%;
    padding: 4px;

    .ant-picker-input > input {
      letter-spacing: -1px;
    }
    .ant-picker-range-separator {
      padding: 2px;
    }
    .ant-picker-active-bar {
      display: none;
    }
  }
  &::after {
    content: "";
    // width: calc(20vw - 32px);
    width: calc(20vw - 36px);
    height: 50px;
  }
}
.ant-input-number-handler-wrap {
  display: none;
}
.searchFormItemStart {
  margin-right: 8px !important;
}
.ant-table-thead > tr > th {
  background-color: #f5f7fa;
}
.ant-table-tbody > tr > td {
  max-width: 300px;
}
.ant-table-column-sorters {
  justify-content: flex-start;
  .ant-table-column-sorter {
    margin-left: 8px;
  }
}
.searchBtn {
  display: flex;
  align-items: flex-end;
  justify-content: space-between;
  margin-bottom: 16px;
  float: right;
  width: calc(20vw - 17px);
  min-width: 260px;
  padding-right: calc(20vw - 17px - 240px);
  box-sizing: border-box;
  margin-right: 28px;
  .ant-btn-text:hover,
  .ant-btn-text:focus {
    background-color: transparent;
    border: solid 1px transparent;
  }
}
#partForm {
  .ant-form-item-label > label {
    height: auto;
  }
}
.searchBtnShow {
  position: absolute;
  width: calc(20vw - 17px);
  min-width: 260px;
  right: 22px;
  padding-right: calc(20vw - 17px - 240px);
  bottom: 18px;
  justify-content: space-between;
  box-sizing: border-box;
  .ant-btn-text:hover,
  .ant-btn-text:focus {
    background-color: transparent;
    border: solid 1px transparent !important;
  }
}

.ant-input-affix-wrapper:focus {
  box-shadow: none;
}
.containerLeft::after {
  box-shadow: none !important;
}
.contentLeft::after {
  box-shadow: none !important;
}
.ant-table-thead th.ant-table-column-has-sorters:hover {
  background-color: #f5f7fa;
}
.ant-btn {
  border-radius: 4px;
  &:hover {
    color: #25b864;
    border: solid 1px #25b864;
  }
}
.anticon-question-circle {
  color: rgba(0, 0, 0, 0.45);
}
.dumpData {
  border: solid 1px #25b864;
  color: #25b864;
  &:hover {
    background: rgb(37, 184, 100, 0.08);
  }
}
.toIndex {
  border: solid 1px #25b864;
  color: #25b864;
  border-radius: 20px;
  width: 363px;
  &:hover {
    background: rgb(37, 184, 100, 0.08);
  }
}
.ant-btn-primary {
  &:hover {
    color: #fff;
    background: #28d070;
    border: solid 1px #28d070;
  }
}
.ant-input-number,
.ant-input,
.ant-select-selector {
  border-radius: 4px !important;
}
.ant-pagination {
  bottom: 35px;
  left: 32px;
  display: flex;
  width: calc(100vw - 64px);
  // bottom: 930px;
}
.ant-table-container::after {
  box-shadow: none !important;
}

.ant-pagination-item:last-child {
  display: none !important;
}
.ant-pagination-item:nth-child(1) {
  display: none !important;
}
.ant-menu-submenu-selected {
  .ant-menu-submenu-arrow {
    color: #25b864;
  }
}
table tr th.ant-table-selection-column,
table tr td.ant-table-selection-column {
  padding-left: 30px;
}

.ant-notification-notice-close {
  display: none !important;
}
.ant-notification {
  z-index: 10000000000000000;
  .ant-notification-notice {
    width: 185px !important;
    border-radius: 4px;
    .ant-notification-notice-content {
      margin-bottom: 4px;
    }
  }
}
.ant-menu-title-content {
  .active {
    color: #25b864 !important;
  }
}
.ant-pagination-item-active {
  background: #25b864;
  // border-radius: 4px;
  &:hover {
    a {
      color: #ffffff;
    }
  }
  a {
    color: #ffffff;
  }
}
.dareninfocode {
  width: 116px;
  height: 116px;
  margin-left: 14px;
}
.dareninfocodeTitle {
  font-size: 12px;
  font-weight: 400;
  color: rgba(0, 0, 0, 0.65);
  line-height: 17px;
  margin-top: 6px;
}
.searchWelcome {
  .ant-modal-confirm-btns {
    display: none;
  }
}
.confirmcontent {
  width: 240px;
}
.ant-layout-content {
}
.codeTooltip {
  .ant-tooltip-inner {
    border-radius: 4px !important;
  }
}
.ant-table-tbody > .ant-table-placeholder > td {
  border-bottom: none;
}
//添加分组弹窗
.addgroup {
  font-size: 14px;
  font-weight: 400;
  color: #000000;
  line-height: 20px;
  letter-spacing: 1px;
  margin-bottom: 16px;
  .nogroup {
    height: 337px;
    display: flex;
    align-items: center;
    background-color: #f8f8f8;
    font-weight: 600;
    color: rgba(0, 0, 0, 0.85);
    line-height: 22px;
    letter-spacing: 1px;
    font-size: 16px;
    padding: 0 16px;
    height: 337px;
background: #FFFFFF;
    border-radius: 4px;
    border: black 1px dashed;
    font-size: 16px;
font-weight: 600;
color: rgba(0, 0, 0, 0.85);
line-height: 22px;
letter-spacing: 1px;
  }
  .groupname {
    font-size: 14px;
    font-weight: 400;
    color: #000000;
    line-height: 20px;
    letter-spacing: 1px;
    margin-bottom: 16px;
  }
}
.creategroup {
  .groupContent {
    .groupItem,.groupItemSelect {
      width: 456px;
      height: 54px;
      background: #f8f8f8;
      border-radius: 4px;
      margin-bottom: 16px;
      cursor: pointer;
      font-size: 16px;
      font-weight: 600;
      color: rgba(0, 0, 0, 0.85);
      line-height: 22px;
      letter-spacing: 1px;
      padding: 16px;
      background: #F8F8F8 100% 100%/100% 100%;
    }
    .groupItemSelect {
      background: url('./../img/group.png')420px 16px/24px 24px no-repeat,rgba(37, 184, 100, 0.1) 100% 100%/100% 100%;
    }
    .nogroup {
      height: 54px;
      display: flex;
      align-items: center;
      background-color: #f8f8f8;
      font-weight: 600;
      color: rgba(0, 0, 0, 0.85);
      line-height: 22px;
      letter-spacing: 1px;
      font-size: 16px;
      padding: 0 16px;
      height: 54px;
  background: #F8F8F8;
      border-radius: 4px;
      border: black 1px dashed;
      font-size: 16px;
  font-weight: 600;
  color: rgba(0, 0, 0, 0.85);
  line-height: 22px;
  letter-spacing: 1px;
    }
  }
}
.attention {
  .ant-form {
    display: block!important;
  }
  .attentiontitle {
    display: flex;
    align-items: flex-end;
   font-size: 18px;
   line-height: 18px!important;
   font-weight: 600;
   color: rgba(0, 0, 0, 0.85);
   line-height: 25px;
   .attentiontitlemark {
     font-size: 14px;
    //  height: 14px;
     line-height: 1;
   }
  }
}

.ant-table table {
  // display: inline-block;
  width: -webkit-max-content;
  width: -moz-max-content!important; //works fine on Mozilla
  width: 
  -ms-max-content!important; //doesn't work on EDGE and MS-Explorer

  width: max-content!important;
  min-width: 100%!important;
}
