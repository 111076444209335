.part {
  :global {
    .partForm {
      display: block;
      .ant-form-item-control-input {
        min-height: 0!important;
      }
      .ant-form-item-label > label {
        max-width: 264px;
        align-items: center;
        display: inline-flex;
        white-space: pre-wrap;
        white-space: pre-line;
        line-height: 16px;
        // max-height:64px ;
    }
    }
  }
  .titleBox {
    height: 32px;
    background: #F8F8F8;
    display: flex;
    align-items: center;
    padding-left: 24px;
    margin-bottom: 24px;
    .title {
      font-size: 14px;
      font-weight: 600;
      color: rgba(0, 0, 0, 0.85);
    }
  }
  .tabBox {
    height: 32px;
    // background: #F8F8F8;
    display: flex;
    align-items: center;
    margin-left: 24px;
    margin-bottom: 24px;
    .tabName {
      font-size: 14px;
      font-weight: 600;
      color: rgba(0, 0, 0, 0.85);
    }
  }
}